import { default as _indexaY4CZ5NnYgMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/_index.vue?macro=true";
import { default as _91_46_46_46cms_93xQG6yWEBy9Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/[...cms].vue?macro=true";
import { default as indexMHHzTFDEnNMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/cart/index.vue?macro=true";
import { default as index0ZKK2BZEz7Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93bgBN4ppgebMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/catalog/[...slug].vue?macro=true";
import { default as indexZ2C3PRTEiaMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93JYcKf1PtqAMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/collection/[...slug].vue?macro=true";
import { default as indexe40zbwNZvsMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/email/index.vue?macro=true";
import { default as indexttuCPHvm1sMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/kontakty/index.vue?macro=true";
import { default as _91_46_46_46slug_93LhfxQAEWp3Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/product/[...slug].vue?macro=true";
import { default as indexptUt7AuyY0Meta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/search/index.vue?macro=true";
import { default as indexQjgYFoTFtjMeta } from "/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _indexaY4CZ5NnYgMeta?.name ?? "_index",
    path: _indexaY4CZ5NnYgMeta?.path ?? "/_index",
    meta: _indexaY4CZ5NnYgMeta || {},
    alias: _indexaY4CZ5NnYgMeta?.alias || [],
    redirect: _indexaY4CZ5NnYgMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/_index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46cms_93xQG6yWEBy9Meta?.name ?? "cms",
    path: _91_46_46_46cms_93xQG6yWEBy9Meta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93xQG6yWEBy9Meta || {},
    alias: _91_46_46_46cms_93xQG6yWEBy9Meta?.alias || [],
    redirect: _91_46_46_46cms_93xQG6yWEBy9Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: indexMHHzTFDEnNMeta?.name ?? "cart",
    path: indexMHHzTFDEnNMeta?.path ?? "/cart",
    meta: indexMHHzTFDEnNMeta || {},
    alias: indexMHHzTFDEnNMeta?.alias || [],
    redirect: indexMHHzTFDEnNMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: index0ZKK2BZEz7Meta?.name ?? "cart-success",
    path: index0ZKK2BZEz7Meta?.path ?? "/cart/success",
    meta: index0ZKK2BZEz7Meta || {},
    alias: index0ZKK2BZEz7Meta?.alias || [],
    redirect: index0ZKK2BZEz7Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bgBN4ppgebMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93bgBN4ppgebMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93bgBN4ppgebMeta || {},
    alias: _91_46_46_46slug_93bgBN4ppgebMeta?.alias || [],
    redirect: _91_46_46_46slug_93bgBN4ppgebMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexZ2C3PRTEiaMeta?.name ?? "catalog",
    path: indexZ2C3PRTEiaMeta?.path ?? "/catalog",
    meta: indexZ2C3PRTEiaMeta || {},
    alias: indexZ2C3PRTEiaMeta?.alias || [],
    redirect: indexZ2C3PRTEiaMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JYcKf1PtqAMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93JYcKf1PtqAMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93JYcKf1PtqAMeta || {},
    alias: _91_46_46_46slug_93JYcKf1PtqAMeta?.alias || [],
    redirect: _91_46_46_46slug_93JYcKf1PtqAMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexe40zbwNZvsMeta?.name ?? "email",
    path: indexe40zbwNZvsMeta?.path ?? "/email",
    meta: indexe40zbwNZvsMeta || {},
    alias: indexe40zbwNZvsMeta?.alias || [],
    redirect: indexe40zbwNZvsMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexttuCPHvm1sMeta?.name ?? "kontakty",
    path: indexttuCPHvm1sMeta?.path ?? "/kontakty",
    meta: indexttuCPHvm1sMeta || {},
    alias: indexttuCPHvm1sMeta?.alias || [],
    redirect: indexttuCPHvm1sMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/kontakty/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93LhfxQAEWp3Meta?.name ?? "product-slug",
    path: _91_46_46_46slug_93LhfxQAEWp3Meta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93LhfxQAEWp3Meta || {},
    alias: _91_46_46_46slug_93LhfxQAEWp3Meta?.alias || [],
    redirect: _91_46_46_46slug_93LhfxQAEWp3Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexptUt7AuyY0Meta?.name ?? "search",
    path: indexptUt7AuyY0Meta?.path ?? "/search",
    meta: indexptUt7AuyY0Meta || {},
    alias: indexptUt7AuyY0Meta?.alias || [],
    redirect: indexptUt7AuyY0Meta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexQjgYFoTFtjMeta?.name ?? "wishlist",
    path: indexQjgYFoTFtjMeta?.path ?? "/wishlist",
    meta: indexQjgYFoTFtjMeta || {},
    alias: indexQjgYFoTFtjMeta?.alias || [],
    redirect: indexQjgYFoTFtjMeta?.redirect,
    component: () => import("/var/www/cilek-kids.ru/frontend/cilek-front-20250318141329/pages/wishlist/index.vue").then(m => m.default || m)
  }
]