<template>
  <form
    @submit.prevent="goPageSearch"
    class="search-input"
  >
    <label class="search-input__input">
      <input
        ref="refSearchInput"
        v-model="searchInput"
        class="search-input__inpt"
        placeholder="Поиск"
      >
      <label class="search-input__img"  @click="goSearch">
        <slot name="search-image">
          <img
            alt="load"
            width="42"
            height="42"
            :src="SearchIcon"
          >
        </slot>
      </label>
    </label>
  </form>
</template>

<script setup lang="ts">
import { ref, useRouter } from '#imports';
import SearchIcon from '#sitis/assets/images/svg/common/search.svg';

const emit = defineEmits<{
  closeSearch:[],
}>();

const searchInput = ref('');
const router = useRouter();

const goPageSearch = () => {
  if ((searchInput.value || '').length < 3) {
    return null;
  }

  emit('closeSearch');
  router.push({ path: '/search', query: { query: searchInput.value } });
  return true;
};
const goSearch = () => {
  if ((searchInput.value || '').length < 3) {
    return null;
  }
  router.push({ path: '/search', query: { query: searchInput.value } });
  return true;
}
</script>

<style>
.search-input {
  position: relative;
}

.search-input:not(.is-page).active .search-input__input {
  position: relative;
  z-index: 101;
}

.search-input__img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 20px;
}
.search-input__img img {
  width: 100%;
  height: 100%;
}

.search-input__input {
  display: flex;
  background: white;
  border: 1px solid #EBEBEB;
  border-radius: 15px;
  height: 40px;
  align-items: center;
}

.search-input__inpt {
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  background-color: transparent;
}

@media (max-width: 1023px) {
  .search-input__input {
    height: 42px;
  }
  .search-input__input img {
    margin-left: 4px;
  }
  .search-input__inpt {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #565656;
    padding-left: 15px;
  }
}
</style>
